import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Layout as ThemeLayout } from '@theme';

const NotFoundPage = () => (
    <Layout>
        <SEO title="Not found" />
        <ThemeLayout.Wrapper style={{ paddingTop: "25vh", paddingBottom: "25vh"}}>
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </ThemeLayout.Wrapper>
    </Layout>
)

export default NotFoundPage
